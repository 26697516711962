import classes from "./Welcome.module.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Welcome = () => {

  const { ref, inView} = useInView({
    threshold: 0.10
  })

  const animation = useAnimation()
  
  useEffect(() => {
    if(inView) {
      animation.start({
        opacity: 1, x: 0,
        transition: {type: "spring", bounce: 0.25, duration: 1, delay: 0.05 }
      }) ;
    }
    if(!inView) {
        animation.start({
          opacity: 0, x: "-100vw"
        }) ;
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <div ref={ref} className={classes.Welcome}>
      <motion.div
        animate={animation}
        className={classes.Column}
      >
        <h2 style={{fontWeight: 600}}>Herzlich willkommen!</h2>
        <p>
          Ich stelle mich vor: ich bin Emma Cascella, avvocato der
          Rechtsanwaltskammer Rom, Doctor iuris (Humboldt Universität zu Berlin)
          und Dottore di ricerca (Sapienza Università di Roma). Meine
          Hauptinteresse sind Italienisches und Europäisches Privatrecht.
          Weitere Informationen erhalten <Link to="/was-kann-ich-fur-sie-tun">Sie hier</Link>
        </p>
      </motion.div>
      <motion.div
        animate={animation}
        className={classes.Column}
        style={{ backgroundColor: "#710205" }}
      >
        <h2 style={{fontWeight: 600}}>Benvenuti!</h2>
        <p>
          Mi presento: sono Emma Cascella, avvocato iscritto all’Ordine degli
          Avvocati di Roma, Doctor iuris (Humboldt Universität zu Berlin) e
          Dottore di ricerca (Sapienza Università di Roma) e mi occupo di
          diritto privato italiano ed europeo. Per ulteriori informazioni sulla
          mia attività, <Link to="/cosa-posso-fare-per-voi">cliccate qui</Link>
        </p>
      </motion.div>
      <motion.div
        animate={animation}
        className={classes.Column}
      >
        <h2 style={{fontWeight: 600}}>Welcome!</h2>
        <p>
          I introduce myself: I am Emma Cascella, avvocato registered with the
          Rome Bar Association, Doctor iuris (Humboldt Universität zu Berlin)
          and Dottore di ricerca (Sapienza Università di Roma) and I deal with
          Italian and European private Law. For more information <Link to="/what-i-can-do-for-you">click here</Link>
        </p>
      </motion.div>
    </div>
  );
};

export default Welcome;
