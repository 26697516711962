import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import Accordion from "react-bootstrap/Accordion";
import bgImgMobile from "../../assets/imgs/002_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/002_2048x1536.jpg";
import classes from "../Section.module.css";

const Tatigkeitsgebiete = () => {
    return (
        <motion.div
            key="tatigkeitsgebiete"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Tätigkeitsgebiete</title>
                <meta
                    name="description"
                    content="Tätigkeitsgebiete - Vertragsrecht - Immobilienrecht - Immobilienrecht - Erbrecht - Familienrecht - Arbeitsrecht"
                />
                <link rel="canonical" href="/tatigkeitsgebiete" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}
            >
                <h1 className={classes.titleH1}>Tätigkeitsgebiete</h1>
            </div>
            <main lang="de" className={classes.content}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className={classes.accHeader}>
                            Vertragsrecht
                        </Accordion.Header>
                        <Accordion.Body>
                            Italienisches allgemeines Vertragsrecht;
                            Europäisches Vertragsrecht.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className={classes.accHeader}>
                            Immobilienrecht
                        </Accordion.Header>
                        <Accordion.Body>
                            Rechtsfragen mit Bezug auf den Kauf oder die
                            Überlassung einer Immobilie: Vorvertrag eines
                            Kaufvertrages, Kaufvertrag, rent to buy, buy to
                            rent, Kaufvertrag mit Eigentumsvorbehalt,
                            Kaufvertrag mit Rückverkaufsklausel, Tauschvertrag,
                            Teilungsvertrag.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header className={classes.accHeader}>
                            Erbrecht
                        </Accordion.Header>
                        <Accordion.Body>
                            Italieniesches Erbrecht: Teilungsvertrag, Testament.
                            Vorbereitung und Vorlegen der Erbschaftserklärung.
                            Fragen zu grenzüberschreitendem Nachlass.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className={classes.accHeader}>
                            Familienrecht
                        </Accordion.Header>
                        <Accordion.Body>
                            Trennung ohne Aulösung des Ehebandes; Ehescheidung
                            oder Veränderungen der Bedingungen der Trennung ohne
                            Aulösung des Ehebandes oder der Ehescheidung.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className={classes.accHeader}>
                            {" "}
                            Gesellschaft- und Körperschaftsrecht; der sogenannte
                            "Dritten Sektor"{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                            Existenzgründung, Veränderung der Satzung
                            (einschließlich Kapitalmaßnahmen) und Schließung der
                            persönlischen Gesellschaften und der
                            Kapitalgesellschaften, sowohl ihre Umwandlung,
                            Verschmelzung und Spaltung. Körperschaften und
                            Vereine. Fragen über den sogennanten "Dritten
                            Sektor".
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header className={classes.accHeader}>
                            Arbeitsrecht
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                Für Arbeitnehmer
                            </strong>
                            Schutz ihrer persönlichen- und vermögenrechtlichen
                            Rechte: Degradierung; Belastigung und Gewalt am
                            Arbeitsplatz; Mobbing; Disziplinarstrafe;
                            Gehaltsunterschiede; Abfindungszahlungen (TFR);
                            rechtswidrige Kündigung; Missbrauch flexibler
                            Arbeitsformen; Annerkeuung unselbständiges
                            Arbeitsverhältnisses; Schwarzarbeit. Für private und
                            öffentliche Arbeitgeber Flexible Arbeitsformen;
                            Änderungen der zugewisenen Aufgaben des
                            Arbeitnehmers; ordnungsgemäße Verwaltung der
                            Disziplinarstrafe; ordnungsgemäße Führung einer
                            Einzelkündigung oder einer Massenentlassung;
                            ordnungsgemäße Kontrollbefugnis auf die
                            Arbeitnehmer; Übertragung des Betriebes;
                            ordnungsgemäße Führung gewerkschaftlicher
                            Beziehungen, betriebliche Zusatzleistungen (welfare
                            aziendale).
                            <strong className={classes.strongSpan}>
                                Für private und öffentliche Arbeitgeber
                            </strong>
                            Flexible Arbeitsformen; Änderungen der zugewisenen
                            Aufgaben des Arbeitnehmers; ordnungsgemäße
                            Verwaltung der Disziplinarstrafe; ordnungsgemäße
                            Führung einer Einzelkündigung oder einer
                            Massenentlassung; ordnungsgemäße Kontrollbefugnis
                            auf die Arbeitnehmer; Übertragung des Betriebes;
                            ordnungsgemäße Führung gewerkschaftlicher
                            Beziehungen, betriebliche Zusatzleistungen (welfare
                            aziendale).
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </main>
        </motion.div>
    );
};

export default Tatigkeitsgebiete;
