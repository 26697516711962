import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import bgImgMobile from "../../assets/imgs/001_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/001_2048x1536.jpg";
import classes from "../Section.module.css";
import { Link } from "react-router-dom";

const CosaPossoFare = () => {
    return (
        <motion.div
            key="cosaPossoFare"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Cosa posso fare per Voi</title>
                <meta
                    name="description"
                    content="avvocato Emma Cascella - consulenza stragiudiziale ed assistenza e
                    rappresentanza giudiziale a persone fisiche, persone giuridiche ed altri
                    enti"
                />
                <link rel="canonical" href="/cosa-posso-fare-per-voi" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}
            >
                <h1 className={classes.titleH1}>Cosa posso fare per Voi</h1>
            </div>
            <main lang="it" className={classes.content}>
                <p>
                    {" "}
                    Presto consulenza stragiudiziale ed assistenza e
                    rappresentanza giudiziale (ad esclusione delle magistrature
                    superiori) a persone fisiche, persone giuridiche ed altri
                    enti nelle materie indicate nella sezione “
                    <Link to="/settori-di-competenza">
                        Settori di competenza
                    </Link>
                    ” .{" "}
                </p>
                <p>
                    {" "}
                    Inoltre, presto consulenza e assistenza per la traduzione in
                    italiano di contratti e documenti a carattere giuridico
                    redatti in tedesco o in inglese e per la traduzione in
                    tedesco ed in inglese di contratti e documenti a carattere
                    giuridico redatti in italiano.{" "}
                </p>
                <p>
                    Il mio curriculum vitae è visionabile nella sezione “
                    <Link to="/curriculum-vitae">Curriculum vitae</Link>”.
                </p>
                <p>
                    I miei recapiti sono reperibili nella sezione “
                    <Link to="/contatti">Contatti”</Link>e l’ubicazione del mio
                    studio è indicata nella sezione “
                    <Link to="/sede">Sede</Link>”.
                </p>
            </main>
        </motion.div>
    );
};

export default CosaPossoFare;
