import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import Accordion from "react-bootstrap/Accordion";
import bgImgMobile from "../../assets/imgs/003_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/003_2048x1536.jpg";
import classes from "../Section.module.css";

const Resume = () => {
    return (
        <motion.div
            key="resume"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Resume</title>
                <meta name="description" content="avvocato Emma Cascella Resume - Professional, Professional education, Other post-academics courses, Foreign Languages, Speaker at convention, Publications" />
                <link rel="canonical" href="/resume" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}            >
                <h1 className={classes.titleH1}>Resume</h1>
            </div>
            <main lang="en" className={classes.content}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className={classes.accHeader}>
                            Professional
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                From October 2013
                            </strong>
                            Avvocato (lawer-sollicitor).
                            <strong className={classes.strongSpan}>
                                2012-2013
                            </strong>
                            Legal consultant in public and administrative Law
                            aspects of real estate purchase agreements.
                            <strong className={classes.strongSpan}>
                                2011-2012
                            </strong>
                            Legal consultant in private and commercial law with
                            reference to cross-border issues; translator of
                            legal documents (English-Italian).{" "}
                            <strong className={classes.strongSpan}>
                                2003-2011
                            </strong>
                            Employee by notary offi: I dealt with public
                            relations with institutional and not-institutional
                            customers; problems analysis and solution,
                            feasibility and set up of notary adminitsrations;
                            legal consultant in commercial, enterprise law,
                            inheritance, contract, real estate, urban law.
                            <strong className={classes.strongSpan}>
                                2002-2003
                            </strong>
                            Legal consultant in urban law problems, real estate
                            registry office and land registry.
                            <strong className={classes.strongSpan}>
                                1999-2002
                            </strong>
                            Notary-pratictioner and assistantcooperation,
                            bibliographic researches, set up of notary
                            administrations.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className={classes.accHeader}>
                            Education
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>2017</strong>
                            Degree of Doctor iuris (Humboldt Universität zu
                            Berlin) and the degree of Dottore di ricerca
                            (Sapienza Università di Roma) on the basis of a
                            joint doctoral thesis. PHD work (published): "UE: la
                            direttiva sui diritti dei pazienti 24/2011/UE e la
                            responsabilità da Behandlungsvertrag (contratto
                            terapeutico) transfrontaliero. Nuovi orizzonti per
                            il diritto italiano e tedesco. (EU: the directive on
                            the rights of patients and the liabilty out of a
                            cross-border treatment agreement. New horizons for
                            Italian and German law) Assessment: summa cum laude.
                            Tutors: Prof. Guido Alpa and Prof. Stefan Grundmann.
                            <strong className={classes.strongSpan}>2013</strong>
                            Master II level in European Private-law at Sapienza
                            Università di Roma. Master final work: Annotation to
                            ECJ Case of 29.3.2012, C-1/11: Tutela dell'ambiente
                            e segreti commerciali nelle spedizioni
                            transfrontaliere di rifiuti (Environmental
                            protection and commercial secrets with reference to
                            cross-border shipments of waste). Grade: 110/110 cum
                            laude. Tutor: Prof. Guido Alpa.
                            <strong className={classes.strongSpan}>1998</strong>
                            Law-degree at Sapienza Università di Roma.
                            Graduation Thesis: “Peculium, Sondergut e patrimoni
                            separati“ (Peculium, Sondergut and separated
                            estate). Grade: 110/110. Thesis Director: Prof.
                            Andrea Di Porto.
                            <strong className={classes.strongSpan}>1994</strong>
                            Diploma of the International Baccalaureate,
                            Discretionary subject: Russian language.United World
                            College of the Adriatic, Trieste, Italy.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header className={classes.accHeader}>
                            Other post-academics courses
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                {" "}
                                November 2012
                            </strong>
                            Rome, Rome Bar Association Lawyer school "Vittorio
                            Emanuele Orlando".
                            <strong className={classes.strongSpan}>
                                July 2012
                            </strong>
                            Belrin, C.N.F. (National Bar association), 7 th
                            Summer- School for the Italian Lawyers "European
                            private-law in progress".
                            <strong className={classes.strongSpan}>
                                June 2012
                            </strong>
                            Rome, University “Regina Apostolorum” et Fidelis
                            International Institute, Rome, Post-graduated course
                            “Etic, Law and Best Practises”.
                            <strong className={classes.strongSpan}>
                                May 2012
                            </strong>
                            Rome, Temple University Beasley School of Law –
                            Sapienza University of Rome Seminar: Specialization
                            in U.S. Commercial Law.
                            <strong className={classes.strongSpan}>
                                October 2011
                            </strong>
                            Rome, “Lionello R. Levi Sandri” Workshop Rome
                            Transnational Law: Method, Hermeneutic and
                            Translation – Research Project on transnational law
                            and legal translation.
                            <strong className={classes.strongSpan}>
                                May 2011
                            </strong>
                            Rome, 645 S.r.l., Professional-course for Mediators.
                            <strong className={classes.strongSpan}>
                                March 2010
                            </strong>
                            Rome, University Luspio Libera San Pio V, Workshop
                            on legal- translation Italian-German:
                            “Übersetzungsprobleme im Privat- und
                            Verfassungsrecht“ (translation-problem in private
                            and constitutional law).
                            <strong className={classes.strongSpan}>2001</strong>
                            Rome, Scuola di Notariato “Anselmo Anselmi“ (notary
                            school), Rome Post-graduate course for Civil Law
                            Notary traniees.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className={classes.accHeader}>
                            Foreign Languages
                        </Accordion.Header>
                        <Accordion.Body>
                            <span className={classes.accH6}>GERMAN</span>
                            <strong className={classes.strongSpan}>
                                {" "}
                                July 2012
                            </strong>
                            Goethe-Zertifikat C2: GROSSES DEUTSCHES SPRACHDIPLOM
                            Goethe Institut e.V. (before I took:
                            Goethe-Zertifikat C1, March 2011; Goethe-Zertifikat
                            B2, July 2010; Goethe-Zertifikat B1, January 2010).
                            <strong className={classes.strongSpan}>
                                July 2011/January 2012
                            </strong>
                            Deutsch-Uni Online, München, DE Legal German.
                            <strong className={classes.strongSpan}>
                                October 2011
                            </strong>
                            District of Bolzano, Service for bi and trilingual
                            language exams Bilingual certificate A.
                            <span className={classes.accH6}>ENGLISH</span>
                            <strong className={classes.strongSpan}>
                                May 2011
                            </strong>
                            ILEC Intenational Legal English Certificate
                            University of Cambridge, Esol Examinations, UK.
                            <span className={classes.accH6}>FRENCH</span>
                            <strong className={classes.strongSpan}>
                                1989-1992
                            </strong>
                            School level
                            <span className={classes.accH6}>RUSSIAN</span>
                            <strong className={classes.strongSpan}>
                                1992-1994
                            </strong>
                            Basic knowledge
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className={classes.accHeader}>
                            Speaker at convention
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                {" "}
                                2018
                            </strong>
                            Conference organised by the Project "Legislazione
                            Europea ed Internazionale"("European and
                            International law") of the Rome Bar
                            Association:"Strumenti di segregazione patrimoniale
                            nell'esperienza italiana ed internazionale" ("Means
                            for separation of assets in the Italian and the
                            intenational experience"). My lecture: "Patrimonio
                            separato (Sondergut) e patrimonio di scopo
                            (Zweckvermögen). Strumenti di segregazione
                            patrimoniale dalla pandettistica ad oggi" ("Special
                            assests (Sondergut) and special-purpose asset
                            (Zweckvermögen): means for separation of assets from
                            the Study of Pandects to nowadays"). Rome, on
                            February 2nd, 2018
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header className={classes.accHeader}>
                            Publications
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                {" "}
                                Monography
                            </strong>
                            "UE: la direttiva sui diritti dei pazienti
                            24/2011/UE e la responsabilità da Behandlungsvertrag
                            (contratto terapeutico) transfrontaliero. Nuovi
                            orizzonti per il diritto italiano e tedesco.
                            Vicalvi, 2017 ( EU: the directive on the rights of
                            patients and the liabilty out of a cross-border
                            treatment agreement. New horizons for Italian and
                            German law).
                            <strong className={classes.strongSpan}>
                            Annotation to a law case
                            </strong>
                            CGUE del 1.10.2015, C.-32/14 - Il controllo sulle clausole abusive nei contratti stipulati fra un professionista ed un consumatore: il notaio come alter ego del giudice, in Orientamenti della Corte di giustizia dell’Unione Europea in materia di tutela del consumatore, in Alpa a cura di, Milano, 2016 („Abusive clauses in contracts between professionals and consumers: the Notary as a Judge’s alter ego? – Annotation to the CJEU of 1.10.2015, C.-32/14“).
                            <strong className={classes.strongSpan}>
                            Encyclopaedic
                            </strong>
                            "
                            <a
                                href="https://www.treccani.it/enciclopedia/contratto-terapeutico_%28Diritto-on-line%29/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Contratto terapeutico
                            </a>
                            ", in diritto.online Treccani, 2016
                            (medical treatment contract).
                            <strong className={classes.strongSpan}>
                                Essays
                            </strong>
                            “Tutela dell'ambiente e segreti commerciali nelle spedizioni transfrontaliere di rifiuti”, Vicalvi, 2016 (“Enviromental protection and commercial secrets in cross-border transport of waste“).
"La mobilità “italiana” dei pazient EU: breve commento al D.Lgs. n. 38 del 2014", in giustiziacivile.com, 23th novembre 2015, in Giustiziacivile.com, 23th November 2015 („The Italian mobility of UE patients: short comment on the D. Lgs. n. 38 of 2014“).
"Diritto transnazionale, traduzione giuridica e tipi di organizzazione imprenditoriale", in Giustiziacivile.com, 23th October 2014 („Transnational law, legal translation and models of enterprises“).
                            <strong className={classes.strongSpan}>
                            Comment
                            </strong>
                            Commento agli Artt. 79-149; 2103-2108, in “Codice civile annotato con la giurisprudenza rilevante” a cura di G. Alpa e G. Iudica, Milano, 2014 („Comment on Artt. 79-149; 2103-2108“ Italian Civil code).
                            <strong className={classes.strongSpan}>
                            Translation
                            </strong>
                            translation into Italian ("Doing Business in Washington State: A Guide to Foreign Business and Investment"), Seattle, 2012 (Publication of the Washington State Bar Association).
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </main>
        </motion.div>
    );
};

export default Resume;
