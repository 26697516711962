import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import bgImgMobile from "../../assets/imgs/004_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/004_2048x1536.jpg";
import classes from "../Section.module.css";

const Contact = () => {
    return (
        <motion.div
            key="contact"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Contact</title>
                <meta name="description" content="avvocato Emma Cascella Contact page - by phone, fax or email" />
                <link rel="canonical" href="/contact" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}            >
                <h1 className={classes.titleH1}>Contact</h1>
            </div>
            <main lang="en" className={classes.content} style={{ textAlign: "center" }}>
                <p>
                    {" "}
                    In order to contact me, email me to
                    <strong className={classes.strongSpan}>
                        info@avvocatoemmacascella.eu
                    </strong>
                    <strong className={classes.strongSpan}>
                        {" "}
                        avv.emma.cascella@gmail.com
                    </strong>
                </p>
                <p>
                or phone me at
                    <strong className={classes.strongSpan}>
                        +39 392 9216983
                    </strong>or at
                    <strong className={classes.strongSpan}>
                        +39 06 97277537
                    </strong>
                    or fax me at
                    <strong className={classes.strongSpan}>
                        +39 06 45555972.
                    </strong>
                </p>
            </main>
        </motion.div>
    );
};

export default Contact;
