import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import Accordion from "react-bootstrap/Accordion";
import bgImgMobile from "../../assets/imgs/003_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/003_2048x1536.jpg";
import classes from "../Section.module.css";

const CurriculumVitae = () => {
    return (
        <motion.div
            key="curriculumVitae"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Curriculum Vitae</title>
                <meta name="description" content="avvocato Emma Cascella - Storia professionale, percorso di istruzione, corsi post-universitari e di formazione, lingue straniere, relazioni a convegni, pubblicazioni" />
                <link rel="canonical" href="/curriculum-vitae" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}            >
                <h1 className={classes.titleH1}>Curriculum Vitae</h1>
            </div>
            <main lang="it" className={classes.content}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className={classes.accHeader}>
                            La mia storia professionale
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                Dall'ottobre 2013:
                            </strong>
                            Avvocato del Foro di Roma.
                            <strong className={classes.strongSpan}>
                                2012-2013
                            </strong>
                            Consulente per primaria società operante in ambito
                            immobiliare.
                            <strong className={classes.strongSpan}>
                                2011-2012
                            </strong>
                            Consulente e giurista linguista.{" "}
                            <strong className={classes.strongSpan}>
                                2003-2011
                            </strong>
                            Lavoratore dipendente di studi notarili.
                            <strong className={classes.strongSpan}>
                                2002-2003
                            </strong>
                            Consulente per primaria società di servizi operanti
                            in ambito di diritto urbanistico-catastale con
                            riferimento anche al sistema tavolare.
                            <strong className={classes.strongSpan}>
                                1999-2002
                            </strong>
                            Praticante notaio e collaboratrice studio notarile.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className={classes.accHeader}>
                            Il mio percorso di istruzione
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>2017</strong>
                            Titoli di "Doctor iuris" e di "Dottore di ricerca";
                            tesi dal titolo: "EU: La direttiva sui diritti dei
                            pazienti 24/2011/EU e la responsabilità da
                            Behandlungsvertrag (contratto terapeutico)
                            transnazionale." Nuovi orizzonti per il diritto
                            italiano e tedesco; valutazione "summa cum laude". I
                            titoli sono stati conseguiti in regime di cotutela
                            fra la “Sapienza” Università di Roma e la Humboldt
                            Universität di Berlino. Tutor: Prof. Guido Alpa e
                            Prof. Stefan Grundmann.
                            <strong className={classes.strongSpan}>2013</strong>
                            Titolo di master di secondo livello in Diritto
                            Privato Europeo presso Sapienza Università di Roma,
                            tesi dal titolo “Tutela dell’ambiente e segreti
                            commerciali nelle spedizioni transfrontaliere di
                            rifiuti: commento alla sentenza della Corte di
                            Giustizia dell’Unione Europea C-1/2011” con la
                            votazione di 110/110 con lode. Tutor: Prof. Guido
                            Alpa.
                            <strong className={classes.strongSpan}>1998</strong>
                            Laurea in giurisprudenza presso Sapienza Università
                            di Roma, tesi di laurea in Istituzioni di diritto
                            romano dal titolo: “Peculium, Sondergut e patrimoni
                            separati” con la votazione di 110/110. Relatore:
                            Prof. Andrea Di Porto.
                            <strong className={classes.strongSpan}>1994</strong>
                            International Baccalaureat (maturità internazionale)
                            sostenendo l’esame finale anche nella materia
                            facoltativa prescelta: lingua russa, presso l'UWCA –
                            United World College of the Adriatic a Duino (TS).
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header className={classes.accHeader}>
                            Corsi post-universitari e di formazione
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                {" "}
                                Settembre-Novembre 2012
                            </strong>
                            Roma, Ordine degli Avvocati di Roma Scuola Forense
                            "Vittorio Emanuele Orlando".
                            <strong className={classes.strongSpan}>
                                Luglio 2012
                            </strong>
                            Berlino, Consiglio Nazionale Forense, 7° Corso
                            Estivo per avvocati italiani "European Private Law
                            in Progress”.
                            <strong className={classes.strongSpan}>
                                Giugno 2012
                            </strong>
                            Roma, Ateneo Pontificio Regina Apostolorum
                            International Institute (Roma), Corso di Alta
                            Formazione in "Etica, Diritto e Best Practises”.
                            <strong className={classes.strongSpan}>
                                Maggio 2012
                            </strong>
                            Roma, Temple University Beasley School of Law 2012,
                            Seminar: Specialization in U.S. Commercial.
                            <strong className={classes.strongSpan}>
                                Ottobre 2011
                            </strong>
                            Roma, Laboratorio 2011 del Seminario permanente
                            “Lionello R. Levi Sandri”, convegno "Laboratori di
                            studi e ricerca sul diritto transnazionale e sulla
                            traduzione giuridica".
                            <strong className={classes.strongSpan}>
                                Maggio 2011
                            </strong>
                            Roma, 645 S.r.l., Corso di formazione per Mediatori.
                            <strong className={classes.strongSpan}>
                                Marzo 2010
                            </strong>
                            Roma, Luspio Libera Università San Pio V, seminario
                            di linguaggio giuridico Italiano-tedesco, "Problemi
                            di traduzione nel diritto privato e nel diritto
                            costituzionale".
                            <strong className={classes.strongSpan}>1999</strong>
                            Roma, Scuola di Notariato “Anselmo Anselmi”.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className={classes.accHeader}>
                            Lingue straniere
                        </Accordion.Header>
                        <Accordion.Body>
                            <span className={classes.accH6}>
                                TEDESCO (certificato)
                            </span>
                            <strong className={classes.strongSpan}>
                                {" "}
                                Luglio 2012
                            </strong>
                            Goethe-Zertifikat C2: GROSSES DEUTSCHES SPRACHDIPLOM
                            Goethe Institut e.V. (conseguiti anche i seguenti
                            certificati: Goethe-Zertifikat C1, marzo 2011;
                            Goethe-Zertifikat B2, luglio 2010; Goethe-Zertifikat
                            B1, gennaio 2010) Goethe-Zertifikat B1, Januar
                            2010).
                            <strong className={classes.strongSpan}>
                                da Luglio 2011 a Gennaio 2012
                            </strong>
                            Corso di Tedesco Giuridico Deutsch-Uni Online,
                            München, DE
                            <strong className={classes.strongSpan}>
                                Ottobre 2011
                            </strong>
                            Attestato di bilinguismo A Provincia Autonoma di
                            Bolzano, Italia, Servizio Esami Bi e Trilinguismo
                            <span className={classes.accH6}>
                                INGLESE (certificato)
                            </span>
                            <strong className={classes.strongSpan}>
                                Maggio 2011
                            </strong>
                            ILEC International Legal English Certificate,
                            University of Cambridge, Esol Examinations, UK.
                            <span className={classes.accH6}>FRANCESE</span>
                            <strong className={classes.strongSpan}>
                                1989-1992
                            </strong>
                            scolastico
                            <span className={classes.accH6}>RUSSO</span>
                            <strong className={classes.strongSpan}>
                                1992-1994
                            </strong>
                            elementare
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className={classes.accHeader}>
                            Relazione a convegni
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                {" "}
                                2018
                            </strong>
                            Convegno "Strumenti di segregazioni patrimoniale
                            nell'esperienza italiana ed internazionale",
                            organizzato dal progetto legislazione Europea ed
                            Internazionale dell'ordine degli Avvocati di Roma,
                            relazione dal titolo: “Patrimonio separato
                            (Sondergut) e patrimonio di scopo (Zweckvermögen):
                            strumenti di segregazione patrimoniale dalla
                            Pandettistica ad oggi.” - 2.01.2018
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header className={classes.accHeader}>
                            Le mie pubblicazioni
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                {" "}
                                Monografia
                            </strong>
                            "EU: La direttiva sui diritti dei pazienti
                            24/2011/EU e la responsabilità da Behandlungsvertrag
                            (contratto terapeutico) transnazionale." Nuovi
                            orizzonti per il diritto italiano e tedesco,
                            Vicalvi, 2017.
                            <strong className={classes.strongSpan}>
                                Nota a sentenza
                            </strong>
                            "Il controllo sulle clausole abusive nei contratti
                            stipulati fra un professionista ed un consumatore:
                            il notaio come alter ego del giudice", in Alpa a
                            cura di, Orientamenti della Corte di Giustizia
                            dell'Unione Europea in materia di tutela del
                            consumatore, 2016.
                            <strong className={classes.strongSpan}>
                                Voce enciclopedica
                            </strong>
                            "
                            <a
                                href="https://www.treccani.it/enciclopedia/contratto-terapeutico_%28Diritto-on-line%29/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Contratto terapeutico
                            </a>
                            ", in diritto.online Treccani, 2016.
                            <strong className={classes.strongSpan}>
                                Saggi
                            </strong>
                            "Tutela dell'ambiente e segreti commerciali nelle
                            spedizioni transfrontaliere di rifiuti", Vicalvi,
                            2016. "La mobilità italiana" dei pazienti EU: breve
                            commento al D.Lgs. 38 del 2014", in
                            Giustiziacivile.com 23 novembre 2015. "Diritto
                            transnazionale, traduzione giuridica e tipi di
                            organizzazione imprenditoriale", in
                            Giustiziacivile.com 23 ottobre 2014.
                            <strong className={classes.strongSpan}>
                            Commento
                            </strong>
                            Commento agli Artt. 79-149; 2103-2108, in "Codice civile annotato con la giurisprudenza rilevante", a cura di G. Alpa e G. Iudica, 2014.
                            <strong className={classes.strongSpan}>
                            Traduzione in italiano
                            </strong>
                            "Doing Business in Washington State: A Guide to
                            Foreign Business and Investment", Seattle, 2012
                            (Veröffentlichung der Washington State Bar
                            Association).
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </main>
        </motion.div>
    );
};

export default CurriculumVitae;
