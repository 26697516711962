import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                color: "rgb(230,230,230)",
                alignItems: "center",
                justifyContent: "center",
                gap: ".7rem",
            }}
        >
            <h1
                style={{
                    fontWeight: "bold",
                    fontSize: "calc(5rem + 2vw)",
                }}
            >
                404
            </h1>
            <h3>Page not found</h3>
            <Link to="/">
                <p>Back to the Homepage</p>
            </Link>
        </div>
    );
};

export default ErrorPage;
