import React from 'react'
import { Routes, Route } from "react-router-dom"
import Home from './Home/Home'
import ErrorPage from './ErrorPage'
import WasKannIchFurSieTun from './DE/WasKannIchFurSieTun'
import Tatigkeitsgebiete from './DE/Tatigkeitsgebiete'
import Lebenslauf from './DE/Lebenslauf'
import Kontakt from './DE/Kontakt'
import Standort from './DE/Standort'
import CosaPossoFare from './IT/CosaPossoFare'
import CurriculumVitae from './IT/CurriculumVitae'
import Contatti from './IT/Contatti'
import Sede from './IT/Sede'
import SettoriCompetenza from './IT/SettoriCompetenza'
import WhatCanDo from './EN/WhatCanDo'
import Resume from './EN/Resume'
import Contact from './EN/Contact'
import Headquarters from './EN/Headquarters'
import PractiseAreas from './EN/PractiseAreas'
import Impressum from './Impressum'
import KontaktContatti from './KontaktContatti'
import StandortSede from './StandortSede'


const RoutesPages = () => {
  return (
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/impressum-disclaimer" element={<Impressum/>} />
        <Route path="/impressum" element={<Impressum/>} />
        <Route path="/kontakt-contatti-contact" element={<KontaktContatti/>} />
        <Route path="/standort-sede-headquarters" element={<StandortSede/>} />
        <Route path="*" element={<ErrorPage />} />
        {/* DE */}
        <Route path="/was-kann-ich-fur-sie-tun" element={<WasKannIchFurSieTun/>} />
        <Route path="/tatigkeitsgebiete" element={<Tatigkeitsgebiete/>} />
        <Route path="/lebenslauf" element={<Lebenslauf/>} />
        <Route path="/curriculum-deutsch" element={<Lebenslauf/>} />
        <Route path="/kontakt" element={<Kontakt/>} />
        <Route path="/standort" element={<Standort/>} />
        {/* IT */}
        <Route path="/cosa-posso-fare-per-voi" element={<CosaPossoFare/>} />
        <Route path="/settori-di-competenza" element={<SettoriCompetenza/>} />
        <Route path="/curriculum-vitae" element={<CurriculumVitae/>} />
        <Route path="/curriculum-italiano" element={<CurriculumVitae/>} />
        <Route path="/contatti" element={<Contatti/>} />
        <Route path="/sede" element={<Sede/>} />
        {/* EN */}
        <Route path="/what-i-can-do-for-you" element={<WhatCanDo/>} />
        <Route path="/areas-of-practise" element={<PractiseAreas/>} />
        <Route path="/resume" element={<Resume/>} />
        <Route path="/curriculum-english" element={<Resume/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/headquarters" element={<Headquarters/>} />
    </Routes>
  )
}

export default RoutesPages