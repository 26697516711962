import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import bgImgMobile from "../../assets/imgs/005_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/005_2048x1536.jpg";
import classes from "../Section.module.css";

const Headquarters = () => {
    return (
        <motion.div
            key="headquarters"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Headquarters</title>
                <meta name="description" content="avvocato Emma Cascella Standort - Office is located in Ostia Lido, Rome, Via Domenico
                    Bonamico 5, 00121 - Italy" />
                <link rel="canonical" href="/headquarters" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}
            >
                <h1 className={classes.titleH1}>Headquarters</h1>
            </div>
            <main lang="en" className={classes.content} style={{ textAlign: "center" }}>
                <p>
                    My office is located in Ostia Lido, Rome, Via Domenico
                    Bonamico 5, 00121
                </p>
                <div className={classes.mapFrame}>
                    <iframe
                        title="Standort - Sede - Headquarters"
                        loading="lazy"
                        style={{ border: 0 }}
                        tabIndex="0"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5954.78093517406!2d12.268696!3d41.73367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1325efe250eb012d%3A0x7b0ab8d02d46039e!2sStudio%20Legale%20Avv.%20Emma%20Cascella!5e0!3m2!1sit!2sit!4v1668432343212!5m2!1sit!2sit"
                        allowFullScreen="allowfullscreen"
                        aria-hidden="false"
                        width="800"
                        height="400"
                        frameBorder="0"
                    ></iframe>
                    <p>
                        avv.emma.cascella@gmail.com
                        <br />
                        info@avvocatoemmacascella.eu
                        <br />
                        Pec: emmacascella@ordineavvocatiroma.org
                        <br /> Mob. +39 392 9216983 – Tel. +39 06 97277537
                        <br />
                        Fax +39 06 45555972
                    </p>
                </div>
            </main>
        </motion.div>
    );
};

export default Headquarters;
