import "./App.css";
import NavigationBar from "./Components/Navbar/NavigationBar";
import Testimonials from "./Components/Testimonials/Testimonials";
import Footer from "./Components/Footer/Footer";
import RoutesPages from "./Pages/RoutesPages";
import ScrollToTop from "./Components/ScrollToTop";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter } from "react-router-dom";

function App() {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <NavigationBar />
                <AnimatePresence>
                    <RoutesPages />
                </AnimatePresence>
                <Testimonials />
                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
