import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bgImgMobile from "../assets/imgs/004_mobile.jpg";
import bgImgDesktop from "../assets/imgs/004_2048x1536.jpg";
import germanyIcon from "../assets/imgs/germany-icon.png";
import italyIcon from "../assets/imgs/italy-icon.png";
import ukIcon from "../assets/imgs/uk-icon.png";
import classes from "./Section.module.css";

const KontaktContatti = () => {
    return (
        <motion.div
            key="kontaktContatti"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Kontakt - Contatti - Contact</title>
                <meta
                    name="description"
                    content="Pagina dei Contatti | Kontaktseite | Contact Page"
                />
                <link rel="canonical" href="/kontakt-contatti-contact" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}
            >
                <h1 className={classes.titleH1}>
                    Kontakt / Contatti / Contact
                </h1>
            </div>
            <main className={classes.content} style={{ textAlign: "center" }}>
                <Container fluid>
                    <Row className="h-100 d-flex flex-column flex-md-row text-center">
                        <Col
                            lang="de"
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                        >
                            <img
                                src={germanyIcon}
                                alt=""
                                width="30"
                                height="30"
                                title="german flag icon"
                            />
                            <p>Ich stelle mich zur Ihren Verfügung unter:</p>
                        </Col>
                        <Col
                            lang="it"
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                            style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                        >
                            <img
                                src={italyIcon}
                                alt=""
                                width="30"
                                height="30"
                                title="italian flag icon"
                            />
                            <p>Potete contattarmi ai seguenti recapiti:</p>
                        </Col>
                        <Col
                            lang="en"
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                        >
                            <img
                                src={ukIcon}
                                alt=""
                                width="30"
                                height="30"
                                title="uk flag icon"
                            />
                            <p>You can reach me at:</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-3">
                            <strong className={classes.strongSpan}>
                                Avv. Emma Cascella
                            </strong>
                            <p>
                                Doctor iuris (Humboldt Universität zu Berlin) &
                                Dottore di Ricerca (Sapienza Università di Roma)
                                <br />
                                Via Domenico Bonamico, 5<br />
                                00121 – Ostia Lido – Roma
                                <br />
                                Italia
                            </p>
                            <p>
                                avv.emma.cascella@gmail.com <br />
                                info@avvocatoemmacascella.eu <br /> Pec:
                                emmacascella@ordineavvocatiroma.org
                                <br />
                                Mob. +39 392 9216983 <br /> Tel. +39 06 97277537{" "}
                                <br />
                                Fax +39 06 45555972
                            </p>
                        </Col>
                    </Row>
                </Container>
            </main>
        </motion.div>
    );
};

export default KontaktContatti;
