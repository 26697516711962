import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import bgImgMobile from "../../assets/imgs/001_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/001_2048x1536.jpg";
import classes from "../Section.module.css";
import { Link } from "react-router-dom";

const WasKannIchFurSieTun = () => {
    return (
        <motion.div
            key="wasKannIchFurSieTun"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Was kann ich für Sie tun</title>
                <meta name="description" content="Avvocato Emma Cascella - Außergerichtlich und gerichtlich berate und vertrete ich natürliche, nicht-natürliche und juristische Personen in Bezug  genannten Rechtsgebiete." />
                <link rel="canonical" href="/was-kann-ich-fur-sie-tun" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}
            >
                <h1 className={classes.titleH1}>Was kann ich für Sie tun</h1>
            </div>
            <main lang="de" className={classes.content}>
                <p>
                    {" "}
                    Außergerichtlich und gerichtlich (aber nicht bei oberen
                    Gerichtshöfen) berate und vertrete ich natürliche,
                    nicht-natürliche und juristische Personen in Bezug auf die
                    in der Sektion “
                    <Link to="/tatigkeitsgebiete">Tätigkeitsgebiete</Link>”
                    genannten Rechtsgebiete.{" "}
                </p>
                <p>
                    {" "}
                    Außerdem berate ich zur Fachübersetzung deutscher oder
                    englischer Verträge oder juristicher Unterlagen ins
                    Italienisch sowohl italienischer Verträge und juristischer
                    Unterlagen ins Deutsche oder Englische.{" "}
                </p>
                <p>
                    Mein Lebenslauf ist in der Sektion “
                    <Link to="/lebenslauf">Lebenslauf</Link>” verfügbar.
                </p>
                <p>
                    {" "}
                    Meine Kontaktangaben befinden sich in der Sektion “
                    <Link to="./kontakt">Kontakt</Link>” und die Lage meines
                    Büros wird in der Sektion “
                    <Link to="/standort">Standort</Link>” angegeben.{" "}
                </p>
            </main>
        </motion.div>
    );
};

export default WasKannIchFurSieTun;
