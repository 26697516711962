import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import bgImgMobile from "../../assets/imgs/004_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/004_2048x1536.jpg";
import classes from "../Section.module.css";

const Contatti = () => {
    return (
        <motion.div
            key="contatti"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Contatti</title>
                <meta name="description" content="avvocato Emma Cascella - Contatti telefonici, telefax ed email" />
                <link rel="canonical" href="/contatti" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}            >
                <h1 className={classes.titleH1}>Contatti</h1>
            </div>
            <main lang="it" className={classes.content} style={{ textAlign: "center" }}>
                <p>
                    Se desiderate contattarmi, potete scrivere una mail ai
                    seguenti indirizzi di posta elettronica
                    <strong className={classes.strongSpan}>
                        info@avvocatoemmacascella.eu
                    </strong>
                    <strong className={classes.strongSpan}>
                        avv.emma.cascella@gmail.com
                    </strong>
                </p>
                <p>
                    oppure telefonare ai seguenti numeri:
                    <strong className={classes.strongSpan}>
                        +39 392 9216983
                    </strong>
                    <strong className={classes.strongSpan}>
                        +39 06 97277537
                    </strong>
                    od inviare un fax al numero:
                    <strong className={classes.strongSpan}>
                        +39 06 45555972.
                    </strong>
                </p>
            </main>
        </motion.div>
    );
};

export default Contatti;
