import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import bgImgMobile from "../../assets/imgs/001_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/001_2048x1536.jpg";
import classes from "../Section.module.css";
import { Link } from "react-router-dom";

const WhatCanDo = () => {
    return (
        <motion.div
            key="whatCanDo"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>What I can do for you</title>
                <meta name="description" content="avvocato Emma Cascella Standort - In Court and out of Court I provide you legal advice and assistance" />
                <link rel="canonical" href="/what-i-can-do-for-you" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}
            >
                <h1 className={classes.titleH1}>What I can do for you</h1>
            </div>
            <main lang="en" className={classes.content}>
                <p>
                    {" "}
                    In Court (except for proceedings in Superior Courts) and out
                    of Court I provide you legal advice and assistance with
                    regard to the matters listed in section “
                    <Link to="/areas-of-practise">
                        Areas of practise
                    </Link>”.{" "}
                </p>
                <p>
                    {" "}
                    Furthermore, I advice you with regard to technical
                    translation of English and German agreements and legal
                    documents into Italian and to technical translation of
                    Italian agreements and legal documents into English and
                    German.{" "}
                </p>
                <p>
                    You can read my resume in section “
                    <Link to="/resume">Resume</Link>”.
                </p>
                <p>
                    {" "}
                    You can get my contact details in section “
                    <Link to="/contact">Contact</Link>” and check the location
                    of the my office in section “
                    <Link to="/headquarters">Headquarters</Link>“.{" "}
                </p>
            </main>
        </motion.div>
    );
};

export default WhatCanDo;
