import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bgImgMobile from "../assets/imgs/impressum_mobile.jpg";
import bgImgDesktop from "../assets/imgs/impressum_2048x1536.jpg";
import germanyIcon from "../assets/imgs/germany-icon.png"
import italyIcon from "../assets/imgs/italy-icon.png"
import ukIcon from "../assets/imgs/uk-icon.png"
import classes from "./Section.module.css";

const Impressum = () => {
    return (
        <motion.div
            key="impressum"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Impressum - Disclaimer</title>
                <meta name="description" content="Impressum Seite | Pagina Impressum | Disclaimer Page" />
                <link rel="canonical" href="/impressum-disclaimer" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}            >
                <h1 className={classes.titleH1}>Impressum / Disclaimer</h1>
            </div>
            <main className={classes.content} style={{ textAlign: "center" }}>
                <Container fluid>
                    <Row className={classes.secRows}>
                        <Col lang="de" className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <img
                                src={germanyIcon}
                                alt=""
                                width="30"
                                height="30"
                                title="german flag icon"
                            />
                            <p>Diese Webseite gehört</p>
                        </Col>
                        <Col lang="it"
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                            style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                        >
                            <img
                                src={italyIcon}
                                alt=""
                                width="30"
                                height="30"
                                title="italian flag icon"
                            />
                            <p>Questo sito appartiene all’</p>
                        </Col>
                        <Col lang="en" className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <img
                                src={ukIcon}
                                alt=""
                                width="30"
                                height="30"
                                title="uk flag icon"
                            />
                            <p>This website belongs to</p>
                        </Col>
                    </Row>
                    <Row className={classes.secRows}>
                        <Col className="p-3">
                            <strong className={classes.strongSpan}>
                                Avv. Emma Cascella
                            </strong>
                            <p>
                                Doctor iuris (Humboldt Universität zu Berlin) &
                                Dottore di Ricerca (Sapienza Università di Roma)
                                <br />
                                Via Domenico Bonamico, 5<br />
                                00121 – Ostia Lido – Roma
                                <br />
                                Italia
                            </p>
                            <p className="text-center">
                                avv.emma.cascella@gmail.com
                                <br />
                                info@avvocatoemmacascella.eu
                                <br /> Pec: emmacascella@ordineavvocatiroma.org
                                <br />
                                Mob. +39 392 9216983 – Tel. +39 06 97277537 –
                                Fax +39 06 45555972
                            </p>
                        </Col>
                    </Row>
                    <Row className={classes.secRows}>
                        <Col className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <strong className={classes.strongSpan}>
                                Versicherungspolice
                            </strong>
                        </Col>
                        <Col
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                            style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                        >
                            <strong className={classes.strongSpan}>
                                Polizza Assicurativa
                            </strong>
                        </Col>
                        <Col className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <strong className={classes.strongSpan}>
                                Insurance
                            </strong>
                        </Col>
                    </Row>
                    <Row className={classes.secRows}>
                        <Col className="p-3">Aon S.p.a.</Col>
                    </Row>
                    <Row className={classes.secRows}>
                        <Col className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <strong className={classes.strongSpan}>
                                MwSt.-Nummer
                            </strong>
                        </Col>
                        <Col
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                            style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                        >
                            <strong className={classes.strongSpan}>P.I.</strong>
                        </Col>
                        <Col className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <strong className={classes.strongSpan}>
                                VAT number
                            </strong>
                        </Col>
                    </Row>
                    <Row className={classes.secRows}>
                        <Col className="p-3">12207191003</Col>
                    </Row>
                    <Row className={classes.secRows}>
                        <Col className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <strong className={classes.strongSpan}>
                                Berufszeichnung und Rechtsanwaltkammer
                            </strong>
                            <p className="text-start">
                                Frau Emma Cascella ist eine in Italien
                                zugelassene avvocato (italienische
                                Berufsbezeichnung für
                                “Rechtsanwältin/Rechtsanwalt”) und Mitglieder
                                der Rechtsanwaltskammer Rom (
                                <a
                                    href="http://www.ordineavvocati.roma.it/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    www.ordineavvocati.roma.it
                                </a>
                                ). Sie unterliegt dem italienischen
                                Verhaltenskodex für Avvocati, der auf der
                                Internetseite{" "}
                                <a
                                    href="http://www.ordineavvocati.roma.it/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    www.consiglionazionaleforense.it
                                </a>{" "}
                                abrufbar ist.
                            </p>
                        </Col>
                        <Col
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                            style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                        >
                            <strong className={classes.strongSpan}>
                                Titolo professionale ed ordine di appartenenza
                            </strong>
                            <p className="text-start">
                                Emma Cascella è avvocato, iscritta all’Ordine
                                degli Avvocati Roma (
                                <a
                                    href="http://www.ordineavvocati.roma.it/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    www.ordineavvocati.roma.it
                                </a>
                                ) ed è soggetta al codice deontologico degli
                                avvocati italiano reperibile su:{" "}
                                <a
                                    href="http://www.ordineavvocati.roma.it/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    www.consiglionazionaleforense.it
                                </a>
                                .
                            </p>
                        </Col>
                        <Col className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <strong className={classes.strongSpan}>
                                Professional qualification and Bar association
                            </strong>
                            <p className="text-start">
                                Mrs. Emma Cascella is an Italian avvocato
                                (Lawyer and Sollicitor), registred with the Rome
                                Bar Association (
                                <a
                                    href="http://www.ordineavvocati.roma.it/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    www.ordineavvocati.roma.it
                                </a>
                                ). She undertakes the Italian Code of conduct
                                for Avvocati available on:{" "}
                                <a
                                    href="http://www.ordineavvocati.roma.it/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    www.consiglionazionaleforense.it
                                </a>
                                .
                            </p>
                        </Col>
                    </Row>
                    <Row className={classes.secRows}>
                        <Col className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <strong className={classes.strongSpan}>
                                Hinweise auf den Inhalt der Webseite
                            </strong>
                            <p className="text-start">
                                Die auf dieser Internetseite erhaltenen
                                Informationen sind allgemeiner Art und keinewegs
                                als Rechtsberatung zu verstehen. Diese Webseite
                                enthält Hinweise auf Webseiten Dritter, deren
                                Inhalt Avv. Emma Cascella keine
                                Veranwortlichkeit trägt.
                            </p>
                        </Col>
                        <Col
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                            style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                        >
                            <strong className={classes.strongSpan}>
                                Informazioni sul contenuto del sito
                            </strong>
                            <p className="text-start">
                                Le informazione contenute in questo sito sono di
                                carattere generale e non costituiscono in alcun
                                modo un parere legale. Il sito contiene rinvii a
                                siti terzi, del cui contenuto l’Avv. Emma
                                Cascella non risponde in alcun modo.
                            </p>
                        </Col>
                        <Col className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <strong className={classes.strongSpan}>
                                Information on the content of this website
                            </strong>
                            <p className="text-start">
                                The information provided in this website do not
                                represent a legal consultance. This website
                                makes postponents to third websites, whose
                                object are not under Avv. Emma Cascella’s
                                liability.
                            </p>
                        </Col>
                    </Row>
                    <Row className={classes.secRows}>
                        <Col className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <strong className={classes.strongSpan}>
                                Urheberrecht
                            </strong>
                            <p className="text-start">
                                Urheberrecht an Fotos und Inhalt dieser Webseite
                                (außer der Zitaten) liegt bei Avv. Emma
                                Cascella.
                            </p>
                        </Col>
                        <Col
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                            style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                        >
                            <strong className={classes.strongSpan}>
                                Diritti d’autore
                            </strong>
                            <p className="text-start">
                                Il diritto di autore sulle fotografie e sul
                                contenuto di questo sito (fatta eccezione per le
                                citazioni) appartiene all’Avv. Emma Cascella.
                            </p>
                        </Col>
                        <Col className="d-flex p-2 flex-column justify-content-around align-items-center h-100">
                            <strong className={classes.strongSpan}>
                                Copyright
                            </strong>
                            <p className="text-start">
                                The Copyright for the published fotos and
                                content (except for quotations) belongs to Avv.
                                Emma Cascella.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </main>
        </motion.div>
    );
};

export default Impressum;
