import classes from "./Testimonials.module.css";
import Carousel from "react-bootstrap/Carousel";
import { FaQuoteLeft } from "react-icons/fa";

import bgTestimonial from "../../assets/imgs/testimonial.jpg";

const Testimonials = () => {
    return (
        <div
            className={classes.Testimonials}
            style={{
                backgroundImage: `url(${bgTestimonial})`,
            }}
        >
            <div className={classes.overlayItem}>
                <Carousel controls={false} indicators={false}>
                    <Carousel.Item>
                        <FaQuoteLeft className={classes.quoteIcon} />
                        <p className={classes.citazione}>
                            Die Werte, auf die sich die Union gründet, sind die
                            Achtung der Menschenwürde, Freiheit, Demokratie,
                            Gleichheit, Rechtsstaatlichkeit und die Wahrung der
                            Menschenrechte einschließlich der Rechte der
                            Personen, die Minderheiten gehören. Diese Werte sind
                            allen Mitgliedstaaten in einer Gesellschaft
                            gemeinsam, die sich durch Pluralismus,
                            Nichtsdiskriminierung, Toleranz, Gerechtigkeit,
                            Solidarität und die Gleichheit von Frauen and
                            Männern auszeichnet.
                        </p>
                        <p className={classes.trattato}>
                            Art. 2 Vertrag über die Europäische Union
                        </p>
                    </Carousel.Item>
                    <Carousel.Item>
                        <FaQuoteLeft className={classes.quoteIcon} />
                        <p className={classes.citazione}>
                            L'Unione si fonda sui valori del rispetto della
                            dignità umana, della libertà, della democrazia,
                            dell'uguaglianza, dello Stato di diritto e del
                            rispetto dei diritti umani, compresi i diritti delle
                            persone appartenenti a minoranze. Questi valori sono
                            comuni agli Stati membri in una società
                            caratterizzata dal pluralismo, dalla non
                            discriminazione, dalla tolleranza, dalla giustizia,
                            dalla solidarietà e dalla parità tra donne e uomini.
                        </p>
                        <p className={classes.trattato}>
                            Art. 2 Trattato sull'Unione Europea
                        </p>
                    </Carousel.Item>
                    <Carousel.Item>
                        <FaQuoteLeft className={classes.quoteIcon} />
                        <p className={classes.citazione}>
                            The Union is founded on the values of respect for
                            human dignity, freedom, democracy, equality, the
                            rule of law and respect for human rights, including
                            the rights of persons belonging to minorities. These
                            values are common to the Member States in a society
                            in which pluralism, non-discrimination, tolerance,
                            justice, solidarity and equality between women and
                            men prevail.
                        </p>
                        <p className={classes.trattato}>
                            Art. 2 Treaty on European Union
                        </p>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    );
};

export default Testimonials;
