import classes from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className={classes.Footer}>
            <div className={classes.copyright}>
                <small>
                    © 2022 Avv. Emma Cascella - MwSt. Nummer/P.I./VAT Number
                    12207191003
                </small>
            </div>
            <div className={classes.footerMenu}>
                <ul>
                    <li>
                        <Link to="/impressum-disclaimer">
                            Impressum / Disclaimer
                        </Link>
                    </li>
                    <li>
                        <Link to="/kontakt-contatti-contact">
                            Kontakt / Contatti / Contact
                        </Link>
                    </li>
                    <li>
                        <Link to="/standort-sede-headquarters">Standort / Sede / Headquarters</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
