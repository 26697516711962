import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import Accordion from "react-bootstrap/Accordion";
import bgImgMobile from "../../assets/imgs/003_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/003_2048x1536.jpg";
import classes from "../Section.module.css";

const Lebenslauf = () => {
    return (
        <motion.div
            key="lenslauf"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Lebenslauf</title>
                <meta
                    name="description"
                    content="Arbeits - und Berufserfahrungen, Meine Bildungsgeschichte, Weiterbildung, Fremdsprachen, Teilnahme an Seminare, Veröffentlichungen"
                />
                <link rel="canonical" href="/lebenslauf" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}
            >
                <h1 className={classes.titleH1}>Lebenslauf</h1>
            </div>
            <main lang="de" className={classes.content}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className={classes.accHeader}>
                            Arbeits - und Berufserfahrungen
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                Seit Oktober 2013:
                            </strong>
                            Avvocato del Foro di Roma.
                            <strong className={classes.strongSpan}>
                                2012-2013
                            </strong>
                            Rechtsberaterin im Rahmen des Immobilienrechts mit
                            besonderer Bezugnahme auf die Prodomverfahren der
                            Notariatsakte.
                            <strong className={classes.strongSpan}>
                                2011-2012
                            </strong>
                            Rechtsberaterin und Sprachjuristin.{" "}
                            <strong className={classes.strongSpan}>
                                2003-2011
                            </strong>
                            Notariatsangestellte.
                            <strong className={classes.strongSpan}>
                                2002-2003
                            </strong>
                            Beraterin im Rahmen des Städtebaurechts und
                            Katasters mit besonderem Bezug auf das tabellarische
                            System.
                            <strong className={classes.strongSpan}>
                                1999-2002
                            </strong>
                            Pratikantin und Mitarbeiterin in einer Notarkanzlei
                            in Rom: u.a. bibliographische Recherchen, Einleitung
                            des Verfahrens.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className={classes.accHeader}>
                            Meine Bildungsgeschichte
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>2017</strong>
                            Doctor iuris (Humboldt Universität zu Berlin) und
                            Dottore di ricerca (Sapienza Università di Roma).
                            Doktorarbeit: "EU: la direttiva sui diritti dei
                            pazienti 24/2011/EU e la responsabilità da
                            Behandlungsvertrag (contratto terapeutico)
                            transnazionale" Nuovi orizzonti per il diritto
                            italiano e tedesco. ("UE: Patientenrechterichtlinie
                            24/2011/UE und die Haftung aus grenzüberschreitendem
                            Behandlungsvertrag." Neue Horizonte im
                            italienieschen und deutschen Recht). Note: "Summa
                            cum Laude". Die akademischen Grade habe ich aufgrund
                            eines Cotutelle-Vertrages zwischen Sapienza
                            Università Rom und Humboldt Universität zu Berlin
                            erlangt. Tutoren: Prof. Guido Alpa und Prof. Stefan
                            Grundmann.
                            <strong className={classes.strongSpan}>2013</strong>
                            Master zweiten Grades im Europäischen Privatrecht an
                            der Sapienza Università Roma, Mastersarbeit:
                            Kommentar zur Entscheidung des Gerichtshofes der
                            europäischen Union vom 29. März 2012, Verfahren
                            C-1/11 "Tutela dell'ambiente e segreti commerciali
                            nelle spedizioni transfrontaliere di rifiuti"
                            ("Umweltschutz und Handelsgeheimnisse in den
                            grenzüberschreitenden Abfalltransporten"); Note:
                            110/110 cum laude. Tutor: Prof. Guido Alpa.
                            <strong className={classes.strongSpan}>1998</strong>
                            Juradiplom an der Sapienza Universität Rom;
                            Diplomarbeit im Rahmen der Grundlagen des römischen
                            Rechts: "Peculium, Sondergut e patrimoni separati"
                            (Peculium, Sondergut und getrennte Vermögen); Note:
                            110/110. Betreuer: Prof. Andrea Di Porto.
                            <strong className={classes.strongSpan}>1994</strong>
                            Diploma of the International Baccalaureate auch in
                            dem fakultativ gewählten Fach, russischer Sprache,
                            an dem „United World College of the Adriatic“
                            (Duino, Trieste).
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header className={classes.accHeader}>
                            Weiterbildung
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                {" "}
                                November 2012
                            </strong>
                            Rom, Rechtsanwaltskammer Rechtsanwaltsschule
                            "Vittorio Emanuele Orlando".
                            <strong className={classes.strongSpan}>
                                Juli 2012
                            </strong>
                            Berlin, C.N.F. (Nationaler Rechtsanwaltsrat),
                            Teilnahme am 7. Sommerkurs für italienische
                            Rechtsanwälte "European Private Law in Progress”
                            ("Noch zum erledigenden Europäischen Privatrecht").
                            <strong className={classes.strongSpan}>
                                Juni 2012
                            </strong>
                            Rom, Päpstliches Athenäum “Regina Apostolorum” et
                            Fidelis International Institut, Weiterbildungskurs
                            "Etica, Diritto e Best Practises" ("Ethik, Recht und
                            Best Practises”).
                            <strong className={classes.strongSpan}>
                                Mai 2012
                            </strong>
                            Rom, Workshop des ständigen Seminars “Lionello R.
                            Levi Sandri”, "Laboratori di studi e ricerca sul
                            diritto transnazionale e sulla traduzione giuridica"
                            (Studien- und Forschungsworkshops im Bereich des
                            transnationalen Rechts und der Übersetzungstheorie).
                            <strong className={classes.strongSpan}>
                                Mai 2011
                            </strong>
                            Rom, 645 S.r.l., Ausbildungskurs für Mediatoren.
                            <strong className={classes.strongSpan}>
                                Marz 2010
                            </strong>
                            Rom, Luspio (UNTN), Seminar über juristische Sprache
                            (italienisch-deutsch), "Problemi di traduzione nel
                            diritto privato e nel diritto costituzionale"
                            ("Übersetzungsprobleme im Privat- und
                            Verfassungsrecht").
                            <strong className={classes.strongSpan}>2001</strong>
                            Rom, Notariatsschule “Anselmo Anselmi“.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className={classes.accHeader}>
                            Fremdsprachen
                        </Accordion.Header>
                        <Accordion.Body>
                            <span className={classes.accH6}>DEUTSCH:</span>
                            <strong className={classes.strongSpan}>
                                {" "}
                                Juli 2012
                            </strong>
                            Goethe-Zertifikat C2: GROSSES DEUTSCHES SPRACHDIPLOM
                            Goethe Institut e.V. (früher habe ich die folgenden
                            Zertifikaten bestanden: Goethe-Zertifikat C1, März
                            2011; Goethe-Zertifikat B2, Juli 2010;
                            Goethe-Zertifikat B1, Januar 2010).
                            <strong className={classes.strongSpan}>
                                Juli 2011/Januar 2012
                            </strong>
                            Deutsch-Uni Online, München, DE Kurs in Juristischem
                            Deutsch.
                            <strong className={classes.strongSpan}>
                                Oktober 2011
                            </strong>
                            Provinz Bozen, Italien, Dienststelle für Zwei- und
                            dreisprachigkeitsprüfungen Zweisprachigkeitsnachweis
                            "A".
                            <span className={classes.accH6}>ENGLISCH:</span>
                            <strong className={classes.strongSpan}>2012</strong>
                            ILEC International Legal English Certificate,
                            University of Cambridge, Esol Examinations, UK.
                            <span className={classes.accH6}>FRANZÖSISCH:</span>
                            <strong className={classes.strongSpan}>
                                1989-1992
                            </strong>
                            Niveau B.
                            <span className={classes.accH6}>RUSSISCH:</span>
                            <strong className={classes.strongSpan}>
                                1992-1994
                            </strong>
                            Niveau A.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className={classes.accHeader}>
                            Teilnahme an Seminare
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                {" "}
                                2018
                            </strong>
                            Seminar des Projektes "Legislazione Europea ed
                            Internazionale" ("Europäische und Internationale
                            Gesetzgebung") der Rechtsanwaltkammer Rom:
                            "Strumenti di segregazione patrimoniale
                            nell'esperienza italiana ed internazionale"
                            ("Rechtsinstrumente für Vermögenstrennung in
                            italienischer und internationaler Erfahrung”). Mein
                            Vortrag: "Patrimonio separato (Sondergut) e
                            patrimonio di scopo (Zweckvermögen). Strumenti di
                            segregazione patrimoniale dalla pandettistica ad
                            oggi" ("Sondergut und Zweckvermögen:
                            Rechtsinstrumente für Vermögenstrennung von der
                            Pandektenlehre bis heute"). Rom, 2. Februar.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header className={classes.accHeader}>
                            Veröffentlichungen
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                {" "}
                                Monographie:
                            </strong>
                            "EU: la direttiva sui diritti dei pazienti
                            24/2011/EU e la responsabilità da Behandlungsvertrag
                            (contratto terapeutico) transnazionale" Nuovi
                            orizzonti per il diritto italiano e tedesco.
                            Vicalvi, 2017 ("UE: Patientenrechterichtlinie
                            24/2011/UE und die Haftung aus grenzüberschreitendem
                            Behandlungsvertrag." Neue Horizonte im
                            italienieschen und deutschen Recht).
                            <strong className={classes.strongSpan}>
                                Anmerkung zu Einem Urteil:
                            </strong>
                            Il controllo sulle clausole abusive nei contratti
                            stipulati fra un professionista ed un consumatore:
                            il notaio come alter ego del giudice, in
                            Orientamenti della Corte di giustizia dell’Unione
                            Europea in materia di tutela del consumatore, a cura
                            di Alpa, Milano, 2016 (Missbräuchliche Klauseln in
                            zwischen Berufstätigen und Verbraucher geschlossenen
                            Verträge: der Notar als alter ego des Richters? -
                            Anmerkung zum EUGH vom 1.10.2015, C.-32/14).
                            <strong className={classes.strongSpan}>
                                Stichwort in Enzyklopädie:
                            </strong>
                            "
                            <a
                                href="https://www.treccani.it/enciclopedia/contratto-terapeutico_%28Diritto-on-line%29/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Contratto terapeutico
                            </a>
                            ", in diritto.online Treccani, 2016
                            (Behandlungsvertrag).
                            <strong className={classes.strongSpan}>
                                Essay
                            </strong>
                            “Tutela dell'ambiente e segreti commerciali nelle
                            spedizioni transfrontaliere di rifiuti” Vicalvi,
                            2016 ("Umweltschutz und Handelsgeheimnisse in den
                            grenzüberschreitenden Abfalltransporten"). "La
                            mobilità “italiana” dei pazienti EU: breve commento
                            al D.Lgs. n. 38 del 2014", in Giustiziacivile.com,
                            23. November 2015, („Die „italienische“ Mobilität
                            der EU Patienten: kurzes Kommentar zum D. Lgs. n. 38
                            vom 2014“). Diritto transnazionale, traduzione
                            giuridica e tipi di organizzazione imprenditoriale",
                            in Giustiziacivile.com, 23. Oktober 2014
                            („Transnationales Recht, juristische Übersetzung und
                            Typen der Unternehmerorganisation“).
                            <strong className={classes.strongSpan}>
                                Kommentar
                            </strong>
                            Codice civile annotato con la giurisprudenza
                            rilevante” a cura di G. Alpa e G. Iudica, Milano,
                            2014. (Kommentar zu den Artt. 79-149; 2103-2108 c.c.
                            (Zivilgesetzbuch mit Anmerkungen der relevanten
                            Rechtsprechung)).
                            <strong className={classes.strongSpan}>
                                Übersetzung ins Italienisch:
                            </strong>
                            "Doing Business in Washington State: A Guide to
                            Foreign Business and Investment", Seattle, 2012
                            (Veröffentlichung der Washington State Bar
                            Association).
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </main>
        </motion.div>
    );
};

export default Lebenslauf;
