import React from "react";
import { motion } from "framer-motion";
import Welcome from "../../Components/Welcome/Welcome";
import Carousel from "react-bootstrap/Carousel";
import slide01 from "../../assets/imgs/slide01.jpg";
import slide02 from "../../assets/imgs/slide02.jpg";
import slide03 from "../../assets/imgs/slide03.jpg";
import "./Home.Style.css";

function Home() {
    return (
        <motion.div
            key="home"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, duration: 1.5 }}
            exit={{ opacity: 0 }}
            className="homeMain"
        >
            <Carousel controls={false} indicators={false}>
                <Carousel.Item>
                    <img
                        className="car--item"
                        src={slide01}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="car--item"
                        src={slide02}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="car--item"
                        src={slide03}
                        alt="Third slide"
                    />
                    <Carousel.Caption className="fw-bolder hero-text"></Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <div className="overlay">
                <h1 className="fw-bold text-center">
                    avvocato
                    <br />
                    Emma Cascella
                </h1>
                <div class="scroll-down"></div>
            </div>
            <Welcome />
        </motion.div>
    );
}

export default Home;
