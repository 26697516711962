import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bgImgMobile from "../assets/imgs/005_mobile.jpg";
import bgImgDesktop from "../assets/imgs/005_2048x1536.jpg";
import germanyIcon from "../assets/imgs/germany-icon.png";
import italyIcon from "../assets/imgs/italy-icon.png";
import ukIcon from "../assets/imgs/uk-icon.png";

import classes from "./Section.module.css";

const StandortSede = () => {
    return (
        <motion.div
            key="standortSede"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Standort - Sede - Headquarters</title>
                <meta
                    name="description"
                    content="Sede dello Studio legale | Standort | Headquarters"
                />
                <link rel="canonical" href="/standort-sede-headquarters" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}
            >
                <h1 className={classes.titleH1}>
                    Standort / Sede / Headquarters
                </h1>
            </div>
            <main className={classes.content} style={{ textAlign: "center" }}>
                <Container fluid>
                    <Row className="h-100 d-flex flex-column flex-md-row">
                        <Col
                            lang="de"
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                        >
                            <img
                                src={germanyIcon}
                                alt=""
                                width="30"
                                height="30"
                                title="german flag icon"
                            />
                            <p>
                                Mein Büro befindet sich in <br /> Ostia Lido,
                                Rom <br /> Via Domenico Bonamico 5, 00121
                            </p>
                        </Col>
                        <Col
                            lang="it"
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                            style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                        >
                            <img
                                src={italyIcon}
                                alt=""
                                width="30"
                                height="30"
                                title="italian flag icon"
                            />
                            <p>
                                Il mio studio si trova a<br />
                                Ostia Lido, Roma
                                <br /> Via Domenico Bonamico 5, 00121
                            </p>
                        </Col>
                        <Col
                            lang="en"
                            className="d-flex p-2 flex-column justify-content-around align-items-center h-100"
                        >
                            <img
                                src={ukIcon}
                                alt=""
                                width="30"
                                height="30"
                                title="uk flag icon"
                            />
                            <p>
                                My office is located in
                                <br />
                                Ostia Lido, Rome
                                <br />
                                Via Domenico Bonamico 5, 00121
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col classNamename="d-flex align-items-center justify-content-center flex-column">
                            <div className={classes.mapFrame}>
                                <iframe
                                    title="Standort - Sede - Headquarters"
                                    loading="lazy"
                                    style={{ border: 0 }}
                                    tabIndex="0"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5954.78093517406!2d12.268696!3d41.73367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1325efe250eb012d%3A0x7b0ab8d02d46039e!2sStudio%20Legale%20Avv.%20Emma%20Cascella!5e0!3m2!1sit!2sit!4v1668432343212!5m2!1sit!2sit"
                                    allowFullScreen="allowfullscreen"
                                    aria-hidden="false"
                                    width="800"
                                    height="400"
                                    frameBorder="0"
                                ></iframe>
                            </div>
                            <p>
                                avv.emma.cascella@gmail.com <br />
                                info@avvocatoemmacascella.eu <br /> Pec:
                                emmacascella@ordineavvocatiroma.org
                                <br />
                                Mob. +39 392 9216983 – Tel. +39 06 97277537{" "}
                                <br />
                                Fax +39 06 45555972
                            </p>
                        </Col>
                    </Row>
                </Container>
            </main>
        </motion.div>
    );
};

export default StandortSede;
