import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import Accordion from "react-bootstrap/Accordion";
import bgImgMobile from "../../assets/imgs/002_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/002_2048x1536.jpg";
import classes from "../Section.module.css";

const SettoriCompetenza = () => {
    return (
        <motion.div
            key="settoriCompetenza"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Settori di competenza</title>
                <meta name="description" content="avvocato Emma Cascella - Diritto dei contratti, immobiliare, delle successioni, di famiglia, del lavoro, societario, degli enti associativi e
                            terzo settore" />
                <link rel="canonical" href="/settori-di-competenza" />
            </Helmet>


            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}            >
                <h1 className={classes.titleH1}>Settori di competenza</h1>
            </div>
            <main lang="it" className={classes.content}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className={classes.accHeader}>
                            Diritto dei contratti
                        </Accordion.Header>
                        <Accordion.Body>
                            Diritto dei contratti italiano e diritto dei
                            contratti europeo.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className={classes.accHeader}>
                            Diritto immobiliare
                        </Accordion.Header>
                        <Accordion.Body>
                            Acquisto e trasferimento di immobili: preliminari di
                            vendita, rent to buy, buy to rent, contratto di
                            vendita con patto di riservato dominio, contratto di
                            vendita con patto di retrovendita, contratti di
                            compravendita, di permuta, di divisione.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header className={classes.accHeader}>
                            Diritto delle successioni
                        </Accordion.Header>
                        <Accordion.Body>
                            Diritto delle successioni italiano: divisioni
                            ereditarie, testamenti. Predisposizione e
                            presentazione della dichiarazione di successione.
                            Questioni ereditarie anche a carattere
                            transfrontaliero.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className={classes.accHeader}>
                            Diritto di famiglia
                        </Accordion.Header>
                        <Accordion.Body>
                            Separazioni e divorzi e modifica delle relative
                            condizioni.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className={classes.accHeader}>
                            {" "}
                            Diritto societario; diritto degli enti associativi e
                            terzo settore{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                            Costituzione, modifica e scioglimento di società di
                            persone e di capitali, ivi comprese le operazioni
                            sul capitale, il trasferimento d’azienda, fusioni,
                            scissioni e trasformazioni omogenee ed eterogenee.
                            Associazioni riconosciute e non e "terzo settore".
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header className={classes.accHeader}>
                            Diritto del lavoro
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                Per i lavoratori:
                            </strong>
                            Tutela di tutti i diritti personali e a contenuto
                            economico; demansionamento; molestie; mobbing;
                            sanzioni disciplinari; differenze retributive;
                            trattamento di fine rapporto; licenziamenti
                            illegittimi; abuso delle forme flessibili di
                            assunzione; riconoscimento della natura subordinata
                            del rapporto; lavoro nero.
                            <strong className={classes.strongSpan}>
                                Per i datori di lavoro privati e pubblici:
                            </strong>
                            Forme flessibili di assunzione; mutamento di
                            mansioni; corretta gestione dei procedimenti
                            disciplinari; corretta gestione dei licenziamenti
                            individuali e collettivi; corretto esercizio del
                            potere di controllo; trasferimento d’azienda e di
                            parte d’azienda; corretta gestione delle relazioni
                            sindacali; welfare aziendale.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </main>
        </motion.div>
    );
};

export default SettoriCompetenza;
