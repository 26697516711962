import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import Accordion from "react-bootstrap/Accordion";
import bgImgMobile from "../../assets/imgs/002_mobile.jpg";
import bgImgDesktop from "../../assets/imgs/002_2048x1536.jpg";
import classes from "../Section.module.css";
//import { Link } from "react-router-dom";

const PractiseAreas = () => {
    return (
        <motion.div
            key="practiseAreas"
            initial={{opacity: 0}}
            animate={{opacity: 1, duration: 1.5}}
            exit={{opacity: 0}}

        >
            <Helmet>
                <title>Areas of practise</title>
                <meta
                    name="description"
                    content="avvocato Emma Cascella Standort - Contractual law, property law, inheritance law, family law, Commercial e company law, Employment & Labour law"
                />
                <link rel="canonical" href="/areas-of-practise" />
            </Helmet>
            <div
                className={classes.parallax}
                style={{
                    backgroundImage:
                        window.innerWidth > 768
                            ? `url(${bgImgDesktop})`
                            : `url(${bgImgMobile})`,
                }}
            >
                <h1 className={classes.titleH1}>Areas of practise</h1>
            </div>
            <main lang="en" className={classes.content}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className={classes.accHeader}>
                            Contractual law
                        </Accordion.Header>
                        <Accordion.Body>
                            Italian law of contract, European Law of contract.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className={classes.accHeader}>
                            Property law
                        </Accordion.Header>
                        <Accordion.Body>
                            Real estate purchase and conveyance: preliminary
                            sales agreement, sale and purchase agreement, rent
                            to buy, buy to rent, purchase agreement with
                            retention of title clause, sale and purchase
                            agreement with repurchase clause, exchange
                            agreement, division agreement.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header className={classes.accHeader}>
                            Inheritance law
                        </Accordion.Header>
                        <Accordion.Body>
                            Italian inheritance law: inheritance division
                            agreements, wills; inheritance probate procedings'
                            setup and submission; cross-border inheritance
                            matters.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className={classes.accHeader}>
                            Family law
                        </Accordion.Header>
                        <Accordion.Body>
                            Legal separation of spouses; divorce; change of
                            legal separation conditions; change of divorce
                            conditions.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className={classes.accHeader}>
                            {" "}
                            Commercial and company law, associations and
                            foundations law and the so-called "third sector"{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                            Setup, emendements and dissolutions of companies;
                            equity transactions; transfer of undertaking;
                            merger, split-off and homogeneous and heterogeneous
                            conversion. Legale entities and the so-called "third
                            sector".
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header className={classes.accHeader}>
                        Employment & Labour law.
                        </Accordion.Header>
                        <Accordion.Body>
                            <strong className={classes.strongSpan}>
                                To workers and emplyees
                            </strong>
                            personal and economics rights protection:
                            professional deskilling; harassment; mobbing;
                            disciplinary measures; wage differences; trattamento
                            di fine rapporto TFR (termination payments);
                            wrongful dismissals; abuse of flexible forms of
                            work; recognition of the employment relationship;
                            illegal employment.
                            <strong className={classes.strongSpan}>
                                To private and public employers
                            </strong>
                            flexible forms of work; change of the employee’s
                            tasks; proper management of disciplinary proceeding;
                            proper management of individual and collective
                            redundancies; proper exercise of controll power;
                            transfer of undertaking or part of the undertaking;
                            proper management of labour union relationship;
                            corporate welfare.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </main>
        </motion.div>
    );
};

export default PractiseAreas;
