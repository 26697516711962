import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../assets/imgs/logo150.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import { motion } from "framer-motion";
import "./NavigationBar.Style.css";

const NavigationBar = () => {
    /* TRANSPARENT / SOLID NAVBAR */
    const [colorChange, setColorchange] = useState(false);

    const changeNavbarColor = () => {
        if (window.scrollY >= 200) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };
    window.addEventListener("scroll", changeNavbarColor);

    /* ANIMATION PARAMETERS */
    const animateFrom = { opacity: 0, y: -40 };
    const animateTo = { opacity: 1, y: 0 };

    return (
        <Navbar
            className={
                colorChange ? "navbar-dark bg-dark" : "navbar-dark colorChange"
            }
            expand="lg"
            fixed="top"
        >
            <Container>
                <Navbar.Brand href="/">
                    <div className="logo-title">
                        <img
                            src={logo}
                            title="logo image"
                            width="32"
                            height="32"
                            alt="logo Emma Cascella"
                        />{" "}
                        <div className="title">avv. Emma Cascella</div>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="">
                    <Nav className="ms-auto fw-semibold">
                        <Nav.Link href="/">Homepage</Nav.Link>
                        <NavDropdown title="DE | IT | EN" id="language-menu">
                            <motion.div
                                initial={animateFrom}
                                animate={animateTo}
                                transition={{ delay: 0.05 }}
                            >
                                <NavDropdown
                                    title="Deutsch"
                                    id="de-language"
                                    className="language-subMenu"
                                >
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.05 }}
                                        >
                                            <Link to="/was-kann-ich-fur-sie-tun">
                                                Was kann ich für Sie tun
                                            </Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.1 }}
                                        >
                                            <Link to="/tatigkeitsgebiete">
                                                Tätigkeitsgebiete
                                            </Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.2 }}
                                        >
                                            <Link to="/lebenslauf">
                                                Lebenslauf
                                            </Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.3 }}
                                        >
                                            <Link to="/kontakt">Kontakt</Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.4 }}
                                        >
                                            <Link to="/standort">Standort</Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </motion.div>
                            <motion.div
                                initial={animateFrom}
                                animate={animateTo}
                                transition={{ delay: 0.1 }}
                            >
                                <NavDropdown
                                    title="Italiano"
                                    id="it-language"
                                    className="language-subMenu"
                                >
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.05 }}
                                        >
                                            <Link to="/cosa-posso-fare-per-voi">Cosa posso fare per Voi</Link>
                                            
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.1 }}
                                        >
                                            <Link to="/settori-di-competenza">
                                                Settori di competenza
                                            </Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.2 }}
                                        >
                                            <Link to="/curriculum-vitae">
                                                Curriculum vitae
                                            </Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.3 }}
                                        >
                                            <Link to="/contatti">Contatti</Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.4 }}
                                        >
                                            <Link to="/sede">Sede</Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </motion.div>
                            <motion.div
                                initial={animateFrom}
                                animate={animateTo}
                                transition={{ delay: 0.2 }}
                            >
                                <NavDropdown
                                    title="English"
                                    id="en-language"
                                    className="language-subMenu"
                                >
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.05 }}
                                        >
                                            <Link to="/what-i-can-do-for-you">
                                                What I can do for you
                                            </Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.1 }}
                                        >
                                            <Link to="/areas-of-practise">
                                                Areas of practise
                                            </Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.2 }}
                                        >
                                            <Link to="/resume">Resume</Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.3 }}
                                        >
                                            <Link to="/contact">Contact</Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        <motion.div
                                            initial={animateFrom}
                                            animate={animateTo}
                                            transition={{ delay: 0.4 }}
                                        >
                                            <Link to="/headquarters">
                                                Headquarters
                                            </Link>
                                        </motion.div>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </motion.div>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavigationBar;
